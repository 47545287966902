import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    apiUrl: "https://jamalouk.shifters.tech/api",
    globalVat: "",
    socketUrl: "jamalouk.shifters.tech",
  },
  getters: {
    base_url(state) {
      return state.apiUrl;
    },
    getGlobalVat(state) {
      return state.globalVat;
    },
    getSocketUrl(state) {
      return state.socketUrl;
    },
  },
  mutations: {
    setVat(state, vatValue) {
      state.globalVat = vatValue;
    },
  },
  actions: {},
  modules: {},
});
