var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reservation"},[_c('formHeader'),_c('div',{staticClass:"content"},[_c('v-card',{staticClass:"mx-auto form-card"},[_c('v-row',[_c('v-col',{staticClass:"left-col d-none d-sm-block",attrs:{"cols":"12","sm":"6"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12","sm":"12"}},[_c('div',{staticClass:"slogan"},[_c('h2',{staticClass:"slogan1 white--text"},[_vm._v("انضم لاكبر مجتمع")]),_c('h2',{staticClass:"slogan2 second-color"},[_vm._v("تجميل فى العالم")])])])],1)],1)],1),_c('v-col',{staticClass:"right-col mt-4 mt-sm-0",attrs:{"cols":"12","sm":"6"}},[(!_vm.showVerifyCode)?_c('div',[_c('h2',{staticClass:"mb-4 reg-title"},[_c('strong',[_vm._v(" تسجيل العميل")])]),_c('div',{staticClass:"login-sec mb-8"},[_c('p',[_vm._v("لديك حساب بالفعل ؟")]),_c('v-btn',{staticClass:"white--text main-btn login-btn",attrs:{"color":"#a53860","to":"/login"}},[_vm._v("تسجيل الدخول")])],1),_c('div',{staticClass:"validation error--text mt-4 text-center"},_vm._l((_vm.validation_errors),function(value,key){return _c('p',{key:key},[_vm._v(" "+_vm._s(value[0])+" ")])}),0),_c('v-form',{ref:"form",staticClass:"my-4",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"الاسم","rules":[
                      _vm.validationRules.required,
                      _vm.validationRules.spaces ]},model:{value:(_vm.register_fields.name),callback:function ($$v) {_vm.$set(_vm.register_fields, "name", $$v)},expression:"register_fields.name"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-row',{staticClass:"d-flex align-baseline"},[_c('v-col',{staticClass:"pa-0",attrs:{"cols":"6","sm":"8"}},[_c('v-text-field',{attrs:{"label":"رقم الجوال","rules":[
                          _vm.validationRules.required,
                          _vm.validationRules.spaces,
                          _vm.mobileRule(_vm.register_fields.mobile) ]},model:{value:(_vm.register_fields.mobile),callback:function ($$v) {_vm.$set(_vm.register_fields, "mobile", $$v)},expression:"register_fields.mobile"}})],1),_c('v-col',{staticClass:"ltr-direction pa-0",attrs:{"cols":"6","sm":"4"}},[_c('v-autocomplete',{attrs:{"label":"كود البلد","rules":[_vm.validationRules.required],"items":_vm.Codes,"required":"","item-text":"title","item-value":"value","disabled":""},on:{"change":function($event){_vm.hideLabel = true}},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_c('v-flex',{attrs:{"xs3":""}},[_c('v-avatar',{attrs:{"size":"25"}},[_c('img',{attrs:{"src":data.item.icon}})])],1),_c('v-flex',{staticClass:"ml-3"},[_vm._v(_vm._s(data.item.title))])]}},{key:"item",fn:function(data){return [_c('v-list-item-avatar',{staticStyle:{"width":"20px","min-width":"20px","height":"20px"}},[_c('img',{attrs:{"src":data.item.icon}})]),_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"innerHTML":_vm._s(data.item.title)}})],1)]}}],null,false,2486463285),model:{value:(_vm.register_fields.country_id),callback:function ($$v) {_vm.$set(_vm.register_fields, "country_id", $$v)},expression:"register_fields.country_id"}})],1)],1)],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"البريد الالكترونى"},model:{value:(_vm.register_fields.email),callback:function ($$v) {_vm.$set(_vm.register_fields, "email", $$v)},expression:"register_fields.email"}})],1),_c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"كلمة المرور","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"rules":[
                      _vm.validationRules.required,
                      _vm.validationRules.spaces,
                      _vm.validationRules.password ],"type":_vm.show1 ? 'text' : 'password'},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.register_fields.password),callback:function ($$v) {_vm.$set(_vm.register_fields, "password", $$v)},expression:"register_fields.password"}})],1)],1),_c('v-row',{staticClass:"text-left my-8"},[_c('v-col',{staticClass:"pa-0"},[_c('v-btn',{staticClass:"white--text main-btn login-btn",attrs:{"color":"#a53860","large":""},on:{"click":function($event){return _vm.register()}}},[_vm._v("تسجيل ")])],1)],1)],1)],1):_c('verifyRegister',{attrs:{"mobile":_vm.register_fields.mobile,"country_id":_vm.register_fields.country_id,"message":_vm.confirmMsg}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }