<template>
  <div>
    <sideMenu ref="sideMenu" :drawer="drawer" />

    <div class="header">
      <v-container fluid>
        <v-row>
          <v-col>
            <router-link to="/">
              <v-img
                src="../assets/logo.png"
                class="logo"
                width="200px"
              ></v-img>
            </router-link>
            <div class="nav-actions">
              <img
                src="@/assets/menu.png"
                alt=""
                class="menu-img"
                @click="openSideMenu"
                width="38px"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import sideMenu from "@/components/sideMenu.vue";

export default {
  name: "formHeader",
  components: { sideMenu },
  data() {
    return {
      drawer: false,
    };
  },
  methods: {
    openSideMenu() {
      this.$refs.sideMenu.toggleDrawer();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";
.header {
  margin-top: 50px;
  margin-right: 50px;
  margin-left: 50px;
  .nav-actions {
    margin-top: 3rem;
    img {
      transform: scaleX(-1);
      cursor: pointer;
    }
  }
}

@media (max-width: 600px) {
  .header {
    margin-right: 12px;
    margin-left: 12px;
  }
}
</style>
