<template>
  <div class="home">
    <headerCom />
    <div class="services" id="services">
      <v-container>
        <v-row>
          <v-col cols="12" sm="6">
            <h2 class="mb-5 services-header main-title">الخدمات</h2>
            <ul class="unstyled-list">
              <li
                class="mb-4 cursor-pointer"
                v-for="(cat, index) in categories"
                @click="goToSearchPage(cat.id)"
                :key="index"
              >
                {{ cat.name }}
              </li>
            </ul>
          </v-col>
          <v-col cols="12" sm="6">
            <div class="d-flex justify-center">
              <div class="ser-img2 mt-4">
                <img src="../assets/cat1.jpg" alt="" width="300px" />
              </div>
              <div class="ser-img1 mb-4">
                <img src="../assets/cat3.jpeg" alt="" width="300px" />
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div class="salons" id="salons">
      <v-container>
        <v-row>
          <v-col>
            <h2 class="white--text mb-4 main-title">الصالونات</h2>
            <!-- <v-sheet class="mx-auto" elevation="8" max-width="800"> -->
            <v-slide-group show-arrows>
              <v-slide-item v-for="(salon, index) in salons" :key="index">
                <v-card
                  class="ma-4"
                  height="230px"
                  width="230px"
                  :href="`/salon/${salon.id}`"
                >
                  <div class="img-div">
                    <img
                      :src="salon.main_image"
                      height="230px"
                      width="230px"
                      alt=""
                    />
                  </div>
                  <div class="content">
                    <p class="name my-2">{{ salon.name }}</p>
                    <!-- <v-icon color="#dea22c">mdi-hexagram</v-icon>
                    <v-icon color="#dea22c">mdi-hexagram</v-icon>
                    <v-icon color="#dea22c">mdi-hexagram</v-icon>
                    <v-icon>mdi-hexagram-outline</v-icon>
                    <v-icon>mdi-hexagram-outline</v-icon> -->
                  </div>
                </v-card>
              </v-slide-item>
            </v-slide-group>
            <!-- </v-sheet> -->
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="pricelist">
      <v-container fluid>
        <v-row>
          <v-col cols="12" sm="12">
            <div class="content d-flex justify-center align-baseline">
              <h2 class="main-title mb-0 main-color">للصالونات</h2>
              <p class="sub my-4 my-sm-0">
                استمتع بمزيد من الامتيازات مع الحساب المدفوع. لمزيد من المعلومات
                ، تحقق من خطط الأشتراك .
              </p>
              <v-btn
                class="white--text login-btn mx-auto"
                color="#a53860"
                to="/pricingList"
                >خطط الاشتراك
                <v-icon color="#ffffff" class="mt-1">mdi-chevron-left</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div
      class="about sec-padding"
      id="about"
      v-if="homeData && homeData.about_us"
    >
      <v-container>
        <v-row class="align-center">
          <v-col cols="12" sm="6">
            <img src="../assets/logo.png" alt="logo" srcset="" class="logo" />

            <div class="text">
              <h2 class="font-bold white--text main-title">عن جمالك</h2>
              <p class="white--text">
                {{ homeData.about_us }}
              </p>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="about-icon-sec">
            <img src="../assets/about-icon.png" alt="logo" class="about-icon" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import headerCom from "@/components/headerCom.vue";

export default {
  name: "Home",
  components: { headerCom },
  data() {
    return {
      rating: 5,
      categories: [],
      model: null,
      salons: [],
      homeData: {},
      socailLinks: [],
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    goToSearchPage(serviceId) {
      this.$router.push("/search?service=" + serviceId);
    },
    getCategories() {
      this.$http
        .get(this.base_url + "/front/getCategories", {})
        .then((response) => {
          console.log(response.data);
          this.categories = response.data.data;
        });
    },
    availabelSalons() {
      this.$http
        .get(this.base_url + "/front/getAvailableSalons", {})
        .then((response) => {
          this.salons = response.data.data;
        });
    },
    getHomeData() {
      this.$http
        .get(this.base_url + "/front/getDataHome", {})
        .then((response) => {
          this.homeData = response.data.data;

          localStorage.setItem("facebook", response.data.data.facebook_link);
          localStorage.setItem("instagram", response.data.data.instagram_link);
          localStorage.setItem("twitter", response.data.data.snapchat_link);
          localStorage.setItem("snapchat", response.data.data.twitter_link);
          localStorage.setItem("whatsApp", response.data.data.whatsApp);
          localStorage.setItem("phone", response.data.data.phone);
          localStorage.setItem("email", response.data.data.email);
          localStorage.setItem("address", response.data.data.address);
        });
    },
  },
  mounted() {
    this.getCategories();
    this.availabelSalons();
    this.getHomeData();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

.services {
  margin: 60px 0;
  .services-header {
    color: $main-color;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px !important;
  }
  .unstyled-list li {
    color: $main-color;
    font-weight: 400 !important;
    font-size: 1.5rem;
  }

  .ser-img1 img {
    border-radius: 0 0 0 50px;
    border-top: 5px solid $main-color;
    height: 100%;
    object-fit: cover;
  }

  .ser-img2 img {
    border-radius: 0 50px 0 0;
    border-bottom: 5px solid $second-color;
    height: 100%;
    object-fit: cover;
  }
}

.salons {
  background-color: $main-color;
  border-radius: 50px 0 0 0;
  padding: 50px 0 150px 0;
  position: relative;
  h2 {
    font-weight: bold;
  }
  .img-div {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 2;
    img {
      border-radius: 0 40px 0 0;
      object-fit: fill;
      width: 100%;
    }
  }
  .content {
    color: #ffffff;
    padding: 0 10px;
    position: absolute;
    z-index: 3;
    bottom: -16px;
    right: 10% !important;
    background-color: #a53860;
    width: 90%;
    border-radius: 2px 15px 2px 2px;
    .name {
      text-align: end;
    }
  }
}

.about {
  background-color: $main-color;
  // margin-top: 50px;
  .logo {
    max-width: 250px;
    margin-bottom: 50px;
  }

  .text {
    .main-title {
      margin-bottom: 50px;
    }

    p {
      font-size: 1.2rem !important;
      line-height: 2;
    }
  }

  .about-icon-sec {
    text-align: left;
    .about-icon {
      max-width: 350px;
      margin: 0 auto;
    }
  }
}

.pricelist {
  padding: 50px 0;
  background-color: #d9d9d9;
  .main-title {
    font-size: 2rem;
    font-weight: bold;
    margin-left: 8px;
  }
  .sub {
    margin-left: 20px;
    font-size: 1.2rem;
  }
}
// ====================================================
.notfound h1 {
  margin: 9% auto;
  font-size: 200px;
  text-align: center;
  color: #fff;
}

.white-text {
  color: #000;
}
.gray-text {
  color: #888888 !important;
}
.black-text {
  color: #000 !important;
}
.second-text {
  color: #ff7dac;
}

.reg-details {
  border: 1px solid #a53860;
  padding: 20px;
  border-radius: 25px;
}

.content {
  // height: 100%;
  .title,
  .brand-name {
    font-size: 1.5rem !important;
  }
  .location {
    font-size: 1rem;
  }
  // background-color: #e5e5e5;
  // padding-bottom: 50px;
  //   height: 50vh;
  .form-card {
    width: 900px;
    height: 560px;
    // overflow: auto;
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    // position: relative;
    // margin-top: -150px !important;
    .right-col {
      height: 630px;
      border-top: 5px solid #a53860;
      border-radius: 0 0 90px 0;
      padding: 1rem 2rem;
      background-color: #fff;
      color: #ffffff;
      margin-bottom: 20px !important;
    }
    .left-col {
      height: 630px;
      border-bottom: 5px solid #fff;
      border-radius: 90px 0 0 0;
      padding: 1rem 2rem;
      background-color: #2d3533;
      color: #ffffff;
      margin-top: 20px;
    }
    .bottom-img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 150px;
      opacity: 0.5;
    }
  }
}
@media (min-width: 960px) {
  .form-card {
    max-width: 50%;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .form-card {
    max-width: 80%;
  }
}

@media (max-width: 600px) {
  .form-card {
    max-width: 80%;
  }
  .about {
    .about-icon-sec {
      text-align: center;
      .about-icon {
        max-width: 200px;
      }
    }
  }

  .pricelist {
    .content {
      flex-direction: column;
      text-align: center;
      h2,
      p {
        width: 100% !important;
      }

      .main-btn {
        margin: 0 auto !important;
      }
    }
  }
}
.rounded-input {
  border-radius: 20px;
}
.countrycode {
  direction: ltr !important;
  text-align: center !important;
  padding-right: 0 !important;
}
.countrycode-col {
  margin-right: 0 !important;
  padding-right: 0 !important;
}
.countrycode-col .v-text-field__slot input {
  text-align: center !important;
}
.main-btn {
  background-color: #a53860 !important;
  color: #ffffff !important;
  border-radius: 5px 5px 5px 20px;
  width: 170px;
  font-size: 1.2rem !important;
}

.success-content {
  .success-msg {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .reg-details {
    border: 1px solid #cccccc;
    padding: 1rem 2rem;
    color: #ffffff;
    border-radius: 20px;
    ul {
      list-style: none;
    }
  }
}
.text-center {
  text-align: center !important;
}

@media (max-width: 600px) {
  .services {
    margin: 30px 0;
    .ser-img1 img {
      width: 150px;
    }

    .ser-img2 img {
      width: 150px;
    }
  }
}
</style>
