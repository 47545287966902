<template>
  <div class="reservation">
    <formHeader />
    <div class="content">
      <v-card class="mx-auto form-card">
        <v-row>
          <v-col cols="12" sm="6" class="left-col d-none d-sm-block">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" class="text-center">
                  <div class="slogan">
                    <h2 class="slogan1 white--text">انضم لاكبر مجتمع</h2>
                    <h2 class="slogan2 second-color">تجميل فى العالم</h2>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col cols="12" sm="6" class="right-col mt-4 mt-sm-0">
            <h2 class="mb-4"><strong> تسجيل الدخول</strong></h2>
            <div class="login-sec mb-8">
              <p>ليس لديك حساب بالفعل ؟</p>
              <v-btn
                class="white--text login-btn"
                color="#a53860"
                to="/registration"
                >سجل كعميل</v-btn
              >

              <v-btn
                class="second-color mx-2 registerAsSalonBtn"
                color="transparent"
                to="/registration/salon"
              >
                سجل كصالون
              </v-btn>
            </div>

            <div class="validation error--text mt-4 text-center">
              <p>
                {{ validation_errors }}
              </p>
            </div>
            <v-form v-model="valid" ref="form" class="my-4">
              <v-row>
                <v-col cols="12" class="pa-0">
                  <v-text-field
                    label="رقم الجوال"
                    name="mobile"
                    v-model="login_fields.mobile"
                    :rules="[
                      validationRules.required,
                      validationRules.spaces,
                      mobileRule(login_fields.mobile),
                    ]"
                  ></v-text-field>
                </v-col>

                <v-col cols="12" class="pa-0">
                  <v-text-field
                    label="كلمة المرور"
                    name="password"
                    v-model="login_fields.password"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[validationRules.required]"
                    :type="show1 ? 'text' : 'password'"
                    @click:append="show1 = !show1"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row class="mt-10">
                <v-col cols="12" class="pa-0 text-left">
                  <v-btn class="white--text login-btn" @click="login()" large
                    >تسجيل الدخول
                  </v-btn>
                </v-col>
                <v-col cols="12" class="pa-0 mt-4">
                  <router-link to="resetPassword" class="forgetPass"
                    >هل نسيت كلمة المرور ؟</router-link
                  >
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import formHeader from "../components/formHeader";
import { validationMixin } from "../mixins/validationMixin";

import { mapGetters } from "vuex";
export default {
  name: "logins",
  mixins: [validationMixin],
  components: { formHeader },
  data() {
    return {
      show1: false,
      valid: false,
      validation_errors: "",
      login_fields: {
        mobile: "",
        password: "",
        cartID: localStorage.getItem("cartId"),
      },
      confirmMsg: "",
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    login() {
      this.validation_errors = "";
      if (this.valid) {
        this.$http
          .post(this.base_url + "/front/login", this.login_fields)
          .then((response) => {
            if (response.data.status.error) {
              this.validation_errors = response.data.status.message;
            } else {
              this.confirmMsg = response.data.status.message;
              localStorage.setItem("token", response.data.data.token);
              localStorage.setItem("user_id", response.data.data.user.id);

              localStorage.setItem("loggedIn", "true");
              let type = response.data.data.type;
              localStorage.setItem("type", type);
              if (response.data.data.type == "salon") {
                localStorage.setItem(
                  "profile_image",
                  response.data.data.logo ? response.data.data.logo : ""
                );
                let completeBasicInfo = response.data.data.completed_basec_info;
                let mobileNum = response.data.data.user.mobile;
                localStorage.setItem("completeBasicInfo", completeBasicInfo);
                localStorage.setItem("salonMobileNumber", mobileNum);

                if (completeBasicInfo == false) {
                  window.location.href = "/salonInfo";
                } else {
                  window.location.href = "/salonbookings";
                }
              } else {
                localStorage.setItem(
                  "profile_image",
                  response.data.data.image ? response.data.data.image : ""
                );
                window.location.href = "/";
              }
            }
          });
      } else {
        this.validate();
      }
    },
  },
  mounted() {
    const token = localStorage.getItem("token");
    if (token) {
      window.location.href = "/";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";
.header {
  margin-top: 50px;
  margin-right: 50px;
  margin-left: 50px;
}
.content {
  // height: 100%;
  .title,
  .brand-name {
    font-size: 1.5rem !important;
  }
  .location {
    font-size: 1rem;
  }
  // background-color: #e5e5e5;
  // padding-bottom: 50px;
  //   height: 50vh;
  .form-card {
    width: 900px;
    // height: 560px;
    // overflow: auto;
    margin: auto;
    margin-bottom: 1rem;
    // position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    // position: relative;
    // margin-top: -150px !important;
    .right-col {
      // height: 630px;
      border-top: 5px solid #a53860;
      border-radius: 0 0 0 60px;
      padding: 1rem 3rem;
      background-color: #fff;
      margin-bottom: 10px !important;
      padding-top: 50px;
      padding-bottom: 50px;

      .registerAsSalonBtn {
        box-shadow: unset !important;
      }
    }
    .left-col {
      // height: 630px;
      border-bottom: 5px solid #fff;
      border-radius: 0 70px 0 0;
      padding: 1rem 2rem;
      background-color: #2d3533;
      margin-top: 10px;

      .slogan {
        position: absolute;
        top: 40%;
        right: 6%;
        font-size: 2rem;
        font-weight: bold;
        .slogan1 {
          // font-size: 2rem;
          margin-bottom: 3rem;
        }
        .slogan2 {
          // font-size: 2rem;
        }
      }
    }
    .bottom-img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 150px;
      opacity: 0.5;
    }
  }
}

.forgetPass {
  cursor: pointer;
  color: #2d3533;
  text-decoration: none;
  font-weight: bold;
}
@media (min-width: 960px) and (max-width: 1264px) {
  .form-card {
    max-width: 80%;
    margin-top: 10rem !important;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .left-col {
    .slogan {
      top: 40%;
      right: 6%;
      font-size: 1.5rem !important;
    }
  }
  .form-card {
    max-width: 90%;
    margin-top: 2rem !important;
  }
}

@media (max-width: 600px) {
  .form-card {
    max-width: 80%;
  }
}
</style>
