<template>
  <div>
    <sideMenu ref="sideMenu" :drawer="drawer" />
    <div class="header">
      <v-container fluid>
        <v-row>
          <v-col cols="8" sm="6">
            <v-img src="../assets/logo.png" class="logo" width="200px"></v-img>
          </v-col>
          <v-col cols="4" sm="6" class="text-left d-none d-sm-block">
            <v-badge
              color="#a53860"
              overlap
              class="notification-badge"
              v-if="notificationExist"
            >
              <v-btn icon to="/salonRequests">
                <v-icon color="#ffffff">mdi-bell</v-icon>
              </v-btn>
            </v-badge>

            <v-btn v-else icon to="/salonRequests">
              <v-icon color="#ffffff">mdi-bell</v-icon>
            </v-btn>

            <v-btn
              class="white--text main-btn login-btn mx-4"
              color="#a53860"
              to="/login"
              v-if="loggedIn != 'true'"
              >تسجيل الدخول</v-btn
            >
            <v-btn v-if="loggedIn" to="/profile" class="mx-4 account-btn" icon>
              <v-icon v-if="!profileImage" color="#ffffff" large
                >mdi-account</v-icon
              >

              <img
                v-else
                class="profile_picture"
                :src="profileImage"
                alt="profile picture"
              />
            </v-btn>
            <cartMenu />
          </v-col>
          <v-col cols="4" sm="6" class="d-block d-sm-none text-left">
            <div class="nav-actions-mobile">
              <img
                src="@/assets/menu.png"
                alt=""
                class="menu-img"
                width="42px"
                @click="openSideMenu"
              />
            </div>
          </v-col>
        </v-row>
        <v-row class="main-head">
          <v-col cols="3" sm="2" class="d-none d-sm-block">
            <div class="nav-actions">
              <img
                src="@/assets/menu.png"
                alt=""
                class="menu-img"
                width="42px"
                @click="openSideMenu"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="10" md="8" class="text-center">
            <div class="search-sec">
              <v-text-field
                v-model="search"
                label="ابحث باسم الصالون, نوع الخدمه, المنطقه ..."
                prepend-icon="mdi-magnify"
                solo
                @keyup.enter="goToSearchPage()"
              >
                <template slot="append-outer">
                  <v-btn icon class="append-icon" @click="goToSearchPage()">
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </template>
              </v-text-field>
            </div>
          </v-col>
          <!-- <v-col cols="3" sm="2"></v-col> -->
        </v-row>
      </v-container>
    </div>

    <notificationPopup
      :snackbar="snackbar"
      @closeSnack="closeSnack"
      color="#ffffff"
      head=""
      text="لديك طلبات حجز جديدة"
    />
  </div>
</template>

<script>
import cartMenu from "@/components/cartMenu.vue";
import sideMenu from "@/components/sideMenu.vue";
import notificationPopup from "@/components/notificationPopup.vue";
import AUTH from "../auth.js";
export default {
  name: "headerCom",
  components: { cartMenu, sideMenu, notificationPopup },
  data() {
    return {
      notificationExist: false,
      snackbar: false,
      search: "",
      drawer: false,
      loggedIn: localStorage.loggedIn,
      profileImage: localStorage.profile_image,
      google_play_link:
        localStorage.google_play_link != "null"
          ? localStorage.google_play_link
          : "",
      app_gallery_link:
        localStorage.app_gallery_link != "null"
          ? localStorage.app_gallery_link
          : "",
      app_store_link:
        localStorage.app_store_link != "null"
          ? localStorage.app_store_link
          : "",
    };
  },
  methods: {
    openPopup() {
      this.snackbar = true;
    },
    closeSnack() {
      this.snackbar = false;
    },
    openSideMenu() {
      this.$refs.sideMenu.toggleDrawer();
    },
    goToSearchPage() {
      this.$router.push("/search?search=" + this.search);
    },
  },
  mounted() {
    if (window.location.protocol == "https:") {
      this.$socket
        .channel("NotificationReceived")
        .listen("NotificationReceived", (e) => {
          // console.log(parseInt(e.user_id) == parseInt(AUTH.getID("salon")));
          if (parseInt(e.user_id) == parseInt(AUTH.getID())) {
            // this.notifications.unshift(e.notification);
            this.notificationExist = true;
            this.snackbar = true;
          }
        });
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

.header {
  background-image: url("../assets/bg.jpg");
  border-radius: 0 0 0 50px;
  background-size: cover;
  height: 80vh !important;
  position: relative;
  padding-top: 50px;
  padding-right: 50px;
  padding-left: 50px;
  .logo {
    width: 200px !important;
  }

  .nav-actions-mobile {
    img {
      width: 32px;
    }
  }
  .main-head {
    // margin-top: 5rem !important;
    height: 60vh;
    align-items: center;
  }
  .search-sec {
    margin: 0 auto !important;
    max-width: 80%;
    // margin-top: 4rem !important;
  }

  .menu-img {
    transform: scaleX(-1);
    cursor: pointer !important;
  }
}

@media (max-width: 600px) {
  .header {
    padding-right: 12px;
    padding-left: 12px;
    padding-top: 25px;
    .main-head {
      .main-nav {
        img {
          width: 30px;
        }
      }
    }
    .search-sec {
      max-width: 100%;
      // margin-top: 4rem !important;
    }
  }
}
</style>
