<template>
  <div class="sideMenu">
    <v-navigation-drawer
      v-model="drawer"
      v-if="drawer"
      width="300px"
      absolute
      bottom
      right
      temporary
    >
      <v-list nav dense>
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
          <v-list-item>
            <v-list-item-title>
              <router-link to="/">
                <img
                  src="../assets/logo-colored.png"
                  alt="logo"
                  class="menu-logo"
                  srcset=""
                />
              </router-link>
            </v-list-item-title>
          </v-list-item>

          <v-btn icon class="my-2" @click="drawer = false">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
          <v-list-item>
            <v-list-item-title @click="goTo('services', 'homeDiv')"
              >الخدمات</v-list-item-title
            >
          </v-list-item>

          <v-list-item>
            <v-list-item-title @click="goTo('salons', 'homeDiv')"
              >الصالونات</v-list-item-title
            >
          </v-list-item>

          <!-- <v-list-item>
            <v-list-item-title @click="goTo('offers', 'homeDiv')"
              >العروض</v-list-item-title
            >
          </v-list-item> -->
          <!-- <v-list-item>
            <v-list-item-title>الباقات</v-list-item-title>
          </v-list-item> -->
          <v-list-item>
            <v-list-item-title @click="goTo('about', 'homeDiv')"
              >عن جمالك</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="goTo('pricingList', 'page')">
              قائمة الاسعار</v-list-item-title
            >
          </v-list-item>
          <v-list-item>
            <v-list-item-title @click="goTo('contact', 'page')"
              >اتصل بنا</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="loggedIn && loginType == 'salon'">
            <v-list-item-title
              v-if="completeBasicInfo == 'true'"
              @click="goTo('salonSettings?tab=1', 'page')"
              >بيانات الصالون</v-list-item-title
            >
            <v-list-item-title v-else @click="goTo('saloninfo', 'page')"
              >بيانات الصالون</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="loggedIn && loginType == 'salon'">
            <v-list-item-title @click="goTo('salonRequests', 'page')"
              >طلبات الحجز</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="loggedIn && loginType == 'salon'">
            <v-list-item-title @click="goTo('salonbookings', 'page')"
              >نتيجة المواعيد</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="loggedIn && loginType != 'salon'">
            <v-list-item-title @click="goTo('profile', 'page')"
              >حسابى</v-list-item-title
            >
          </v-list-item>

          <v-list-item v-if="loggedIn && loginType != 'salon'">
            <v-list-item-title @click="goTo('mybookings', 'page')"
              >حجوزاتى</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-if="!loggedIn">
            <v-list-item-title @click="goTo('login', 'page')"
              >تسجيل الدخول</v-list-item-title
            >
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-title @click="logout()"
              >تسجيل الخروج</v-list-item-title
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "sideMenu",
  props: {},
  data() {
    return {
      drawer: false,
      group: null,
      loggedIn: localStorage.getItem("loggedIn"),
      completeBasicInfo: localStorage.getItem("completeBasicInfo"),
      loginType: localStorage.getItem("type"),
    };
  },
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
    goTo(secParam, location) {
      if (location == "homeDiv") {
        if (this.$route.path == "/") {
          document
            .getElementById(secParam)
            .scrollIntoView({ behavior: "smooth", block: "center" });
        } else {
          this.$router.push("/#" + secParam);
        }
      } else {
        this.$router.push("/" + secParam);
      }

      this.drawer = false;
    },
    logout() {
      localStorage.removeItem("token");
      localStorage.removeItem("loggedIn");
      localStorage.removeItem("type");
      localStorage.removeItem("email");
      localStorage.removeItem("phone");
      window.location.href = "/";
    },
  },
  mounted() {
    if (localStorage.getItem("token")) {
      this.loggedIn = true;
    } else {
      this.loggedIn = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.sideMenu {
  .menu-logo {
    max-width: 200px;
    margin-bottom: 50px;
  }
}
</style>
