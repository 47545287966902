import Vue from "vue";
import VueRouter from "vue-router";
// import Home from "../views/Home.vue";
import Reservation from "../views/reservation.vue";
import ReservationWeb from "../views/reservation-web.vue";
import registrationAsSalon from "../views/registrationAsSalon.vue";
import registration from "../views/registration.vue";
import login from "../views/login.vue";
import resetPassword from "../views/resetPassword.vue";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: { background: false },
  },
  {
    path: "/registration",
    name: "registration",
    component: registration,
    meta: { background: true },
    beforeEnter: (to, from, next) => {
      if (localStorage.loggedIn == "true") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/registration/salon",
    name: "registrationAsSalon",
    component: registrationAsSalon,
    meta: { background: true },
    beforeEnter: (to, from, next) => {
      if (localStorage.loggedIn == "true") {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/login",
    name: "login",
    component: login,
    meta: { background: true },
  },
  {
    path: "/resetPassword",
    name: "resetPassword",
    component: resetPassword,
    meta: { background: true },
  },
  {
    path: "/reservation/:slug",
    name: "Reservation",
    component: Reservation,
    meta: { background: true },
  },
  {
    path: "/reserve-web/:slug",
    name: "ReservationWeb",
    component: ReservationWeb,
    meta: { background: true },
  },
  {
    path: "/confirmed/:id",
    name: "reservation.confirmed",

    component: () => import("../views/after_reservation.vue"),
    meta: { background: true },
  },
  {
    path: "/invoice/:invoice_number",
    name: "invoicePage",

    component: () => import("../views/invoice.vue"),
    meta: { background: false },
  },
  {
    path: "/salon/:id",
    name: "SalonProfile",

    component: () => import("../views/SalonProfile.vue"),
    meta: { background: false },
  },
  {
    path: "/branch",
    name: "branch",

    component: () => import("../views/branch.vue"),
    meta: { background: false },
  },
  {
    path: "/terms",
    name: "terms",

    component: () => import("../views/terms.vue"),
    meta: { background: false },
  },
  {
    path: "/privacyPolicy",
    name: "privacyPolicy",

    component: () => import("../views/privacyPolicy.vue"),
    meta: { background: false },
  },
  {
    path: "/contact",
    name: "contact",

    component: () => import("../views/contact.vue"),
    meta: { background: false },
  },
  {
    path: "/reserve/steps",
    name: "steps",

    component: () => import("../views/Reservation/steps.vue"),
    meta: { background: false },
  },
  {
    path: "/pricingList",
    name: "pricingList",

    component: () => import("../views/pricingList.vue"),
    meta: { background: false },
  },
  {
    path: "/search",
    name: "search",

    component: () => import("../views/searchPage.vue"),
    meta: { background: false },
  },
  {
    path: "/payment",
    name: "planPayment",

    component: () => import("../views/planPayment.vue"),
    meta: { background: false },
  },
  {
    path: "/profile",
    name: "profileBasicInfo",

    component: () => import("../views/profileBasicInfo.vue"),
    meta: { background: false },
    beforeEnter: (to, from, next) => {
      if (localStorage.loggedIn == "true") {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/salonInfo",
    name: "salonBasicInfo",

    component: () => import("../views/salon/salonBasicInfo.vue"),
    meta: { background: false },
    beforeEnter: (to, from, next) => {
      if (localStorage.loggedIn == "true") {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/salonSettings",
    name: "salonSettings",

    component: () => import("../views/salon/salonSettings.vue"),
    meta: { background: false },
    beforeEnter: (to, from, next) => {
      if (localStorage.loggedIn == "true") {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/saloncalendar",
    name: "salonCalendar",

    component: () => import("../views/salon/salonCalendar.vue"),
    meta: { background: false },
    beforeEnter: (to, from, next) => {
      if (localStorage.loggedIn == "true") {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/salonRequests",
    name: "bookingsRequests",

    component: () => import("../views/salon/bookingsRequests.vue"),
    meta: { background: false },
    beforeEnter: (to, from, next) => {
      if (localStorage.loggedIn == "true") {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/salonbookings",
    name: "bookings",

    component: () => import("../views/salon/bookings.vue"),
    meta: { background: false },
    beforeEnter: (to, from, next) => {
      if (localStorage.loggedIn == "true") {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "/mybookings",
    name: "myBookings",

    component: () => import("../views/myBookings.vue"),
    meta: { background: false },
    beforeEnter: (to, from, next) => {
      if (localStorage.loggedIn == "true") {
        next();
      } else {
        next("/");
      }
    },
  },
  {
    path: "*",
    name: "NotFoundComponent",
    component: () => import("../views/404.vue"),
    meta: { background: true },
  },
];

const router = new VueRouter({
  routes,
  mode: "history",
});

export default router;
