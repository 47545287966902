import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import axios from "axios";
import jQuery from "jquery";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
window.jQuery = window.$ = jQuery;
import * as VueGoogleMaps from "vue2-google-maps";
import Echo from "laravel-echo";

import moment from "moment";
Vue.prototype.moment = moment;

require("./assets/custom.css");

Vue.config.productionTip = false;
if (window.location.protocol == "https:") {
  const socketUrl = store.getters.getSocketUrl;
/* eslint-disable */
  const Pusher = require("pusher-js");
  Vue.prototype.$socket = new Echo({
    broadcaster: "pusher",
    key: "jamaloukAppKey",
    cluster: "mt1",
    wsHost: socketUrl,
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    enabledTransports: ["ws", "wss"],
    forceTLS: true
  });
}






Vue.prototype.$http = axios;
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyACgY2Kl1e1DAM80BgiwVUo8BKXzJ5tU7w",
    libraries: "places",
  },
});
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
