var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.type == 'addEditAddress' ? 'addEditMapAddress' : ''},[_c('div',[_c('label',{staticStyle:{"width":"100%"}},[_c('gmap-autocomplete',{staticClass:"form-control",staticStyle:{"border":"1px solid #a53860","padding":"10px","width":"100%"},attrs:{"options":{ fields: ['geometry'] }},on:{"place_changed":_vm.setPlace}})],1),_c('br')]),_c('br'),_c('GmapMap',{ref:"mymap",staticClass:"mx-auto",attrs:{"center":_vm.center,"zoom":7,"options":{
      zoomControl: false,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
      disableDefaultUi: false,
    }}},[_c('gmap-marker',{attrs:{"position":_vm.marker,"clickable":true,"draggable":true},on:{"click":function($event){_vm.center = _vm.marker.position},"dragend":_vm.updateCoordinates}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }