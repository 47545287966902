<template>
  <div>
    <v-snackbar
      v-model="snackbar"
      left
      top
      :color="color"
      :timeout="timeout"
      class="notification"
    >
      <v-btn icon @click="closeSnack" class="close-btn">
        <v-icon color="#a53860">mdi-close</v-icon>
      </v-btn>
      <div class="d-flex align-center">
        <p class="logo mx-5">
          <img src="../assets/icon.png" width="50px" alt="" />
        </p>
        <div>
          <p class="text mb-1">
            <a href="/salonRequests">
              {{ text }}
            </a>
          </p>
        </div>
      </div>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "notificationPopup",
  props: ["snackbar", "color", "text", "head"],
  data() {
    return {
      timeout: 4000,
    };
  },
  methods: {
    closeSnack() {
      this.$emit("closeSnack");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/_variables.scss";

.notification {
  .v-snack__wrapper {
    width: 100px !important;
  }
  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
  .text {
    color: #000000 !important;
  }
}
</style>
