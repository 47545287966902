<template>
  <v-app :class="pageHasBackground() ? '' : 'no-background'">
    <v-main>
      <router-view />
    </v-main>
    <footerComp />
  </v-app>
</template>

<script>
import footerComp from "@/components/footerComp.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",
  components: { footerComp },

  data: () => ({
    //
  }),
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    pageHasBackground() {
      return this.$route.meta.background;
    },

    getHomeData() {
      this.$http
        .get(this.base_url + "/front/getDataHome", {})
        .then((response) => {
          // this.homeData = response.data.data;

          localStorage.setItem(
            "app_gallery_link",
            response.data.data.app_gallery_link
          );
          localStorage.setItem(
            "app_store_link",
            response.data.data.app_store_link
          );
          localStorage.setItem(
            "google_play_link",
            response.data.data.google_play_link
          );
          localStorage.setItem("facebook", response.data.data.facebook_link);
          localStorage.setItem("instagram", response.data.data.instagram_link);
          localStorage.setItem("twitter", response.data.data.snapchat_link);
          localStorage.setItem("snapchat", response.data.data.twitter_link);
          localStorage.setItem("whatsApp", response.data.data.whatsApp);
          localStorage.setItem("phone", response.data.data.phone);
          localStorage.setItem("email", response.data.data.email);
          localStorage.setItem("address", response.data.data.address);
        });
    },
  },

  mounted() {
    this.getHomeData();
  },
};
</script>

<style lang="scss" scoped>
// .v-application {
//   background-color: #e5e5e5 !important;
// }
</style>
