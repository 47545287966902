<template>
  <v-menu
    v-model="menu"
    class="cart-menu"
    :close-on-content-click="false"
    :nudge-width="250"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        :color="cartItems && cartItems.length > 0 ? '#a53860' : ''"
        :content="cartItems && cartItems.length > 0 ? cartItems.length : ''"
      >
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="#ffffff" large>mdi-cart</v-icon>
        </v-btn>
      </v-badge>
    </template>

    <v-card>
      <v-card-text>
        <div class="cart">
          <div class="cart-head text-center">
            <h3 class="main-color mb-4">السلة</h3>
            <hr class="cart-hr" />
            <p
              class="salon-name mb-0 main-color"
              v-if="cartItems.length > 0 && cartItems[0].salon"
            >
              <v-icon color="#ffffff" class="salon-icon"
                >mdi-chevron-right</v-icon
              >
              <span>
                {{ cartItems[0].salon }}
              </span>
            </p>
          </div>

          <div class="cart-content mt-4" v-if="cartItems.length > 0">
            <div v-for="item in cartItems" :key="item.id">
              <v-row class="align-baseline">
                <v-col class="pa-0 mb-2" cols="6">
                  <div class="title main-black">
                    <p class="mb-0">
                      {{ item.session_name }}
                    </p>
                  </div>
                </v-col>
                <v-col class="pa-0 mb-2 text-center" cols="3">
                  <p class="price second-color mb-0">{{ item.price }} ر.س</p>
                </v-col>
                <v-col class="pa-0 mb-2 text-center" cols="3">
                  <p class="delete mb-0">
                    <v-btn icon small @click="deleteCartItem(item.id)">
                      <v-icon color="#46101f">mdi-delete</v-icon>
                    </v-btn>
                  </p>
                </v-col>
              </v-row>
              <hr class="cart-hr" />
              <!-- <div
                  class="cart-item d-flex justify-space-between align-items-baseline"
                ></div> -->
            </div>
          </div>
          <div class="cart-total mt-4" v-if="totalPrice">
            <p class="total main-black">الاجمالى :</p>
            <p class="num main-black mb-8">{{ totalPrice }} ر.س</p>
          </div>

          <div class="booking text-center" v-if="cartItems.length > 0">
            <v-btn class="login-btn" large @click="confirmReservation"
              >تاكيد الحجز

              <v-icon color="#a53860" class="cart-btn-icon"
                >mdi-chevron-left</v-icon
              >
            </v-btn>
          </div>

          <div v-else>
            <p class="text-center mt-4">السلة فارغة</p>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "cartMenu",
  props: ["active"],
  data() {
    return {
      menu: false,
      cartItems: [],
      cartItemsIds: [],
      cartId: "",
      totalPrice: "",
      loggedIn: localStorage.getItem("loggedIn"),
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    showCart() {
      if (localStorage.getItem("cartId")) {
        this.cartId = localStorage.getItem("cartId");
      }
      if (this.cartId) {
        this.$http
          .get(this.base_url + "/front/showCart?cartID=" + this.cartId, {})
          .then((response) => {
            let vat = response.data.data.vat;
            this.$store.commit("setVat", vat);
            this.cartId = response.data.data.cartID;
            this.cartItems = response.data.data.items;
            this.totalPrice = response.data.data.totalPrice;
            this.cartItemsIds = [];

            this.cartItems.forEach((item) => {
              this.cartItemsIds.push(item.id);
            });
          });
      }
    },
    updateCart(cartId, cartItems, totalPrice, cartItemsIds) {
      console.log("updated");
      this.cartId = cartId;
      this.cartItems = cartItems;
      this.totalPrice = totalPrice;
      this.cartItemsIds = cartItemsIds;
    },

    deleteCartItem(itemId) {
      this.$http
        .get(
          this.base_url +
            "/front/removeFromCart/" +
            itemId +
            "?cartID=" +
            this.cartId,
          {}
        )
        .then((response) => {
          this.cartItems = response.data.data.items;
          this.totalPrice = response.data.data.totalPrice;
          this.cartItemsIds = [];

          this.$emit(
            "parentUpdateCart",
            this.cartId,
            this.cartItems,
            this.totalPrice,
            this.cartItemsIds
          );
        });
    },
    confirmReservation() {
      if (this.loggedIn == "true") {
        this.$router.push("/reserve/steps?step=1");
      } else {
        this.$router.push("/login");
      }
    },
  },
  mounted() {
    this.showCart();
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";

.cart {
  width: 100%;
  margin: 0 auto;
  border-radius: 0 0 0 40px;
  padding: 1rem;
  .cart-hr {
    margin: 15px 0;
    margin-bottom: 15px !important;
    border-top: 1px solid #cccccc;
  }
  .cart-head {
    h3 {
      font-size: 1.5rem;
    }
    hr {
      width: 30%;
      margin: 0 auto;
      margin-bottom: 12px !important;
      border-top: 1px solid #cccccc;
    }
  }

  .salon-name {
    font-size: 1.25rem !important;
    font-weight: bold;
    .salon-icon {
      background-color: $main-color;
      width: 25px;
      height: 25px;
      border-radius: 50%;
    }
  }
  .cart-total {
    .num {
      font-size: 2rem !important;
    }
  }

  .cart-btn-icon {
    width: 25px;
    height: 25px;
    background-color: #ffffff;
    border-radius: 50%;
    margin-right: 10px;
  }
}
</style>
