const AUTH = {
  getID(type = "salon") {
    if (type == "salon")
      return localStorage.type == "salon" && localStorage.user_id !== undefined
        ? localStorage.user_id
        : null;
    else
      return localStorage.type == "client" && localStorage.user_id !== undefined
        ? localStorage.user_id
        : null;
  },
};

export default AUTH;
