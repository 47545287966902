<template>
  <div class="reservation">
    <formHeader />
    <div class="content">
      <v-card class="mx-auto form-card">
        <v-row>
          <v-col cols="12" sm="6" class="left-col d-none d-sm-block">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" class="text-center">
                  <div class="slogan">
                    <h2 class="slogan1 white--text">انضم لاكبر مجتمع</h2>
                    <h2 class="slogan2 second-color">تجميل فى العالم</h2>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col cols="12" sm="6" class="right-col mt-4 mt-sm-0">
            <div class="step-1" v-if="step == 1">
              <h2 class="form-title"><strong> ارسال كود التحقق</strong></h2>
              <div class="login-sec mt-4">
                <p>ادخل رقم الجوال</p>
              </div>

              <div
                class="validation error--text mt-4 text-center"
                v-if="validation_errors"
              >
                <p>
                  {{ validation_errors }}
                </p>
              </div>
              <p v-if="confirmMsg" class="text-center mt-4">{{ confirmMsg }}</p>
              <v-form v-model="valid" ref="form" class="my-12">
                <v-row>
                  <v-col cols="12">
                    <v-row class="d-flex align-baseline">
                      <v-col class="pa-0" cols="6" sm="8">
                        <v-text-field
                          label="رقم الجوال"
                          name="mobile"
                          v-model="mobile"
                          :rules="[
                            validationRules.required,
                            validationRules.spaces,
                            mobileRule(mobile),
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="4" class="ltr-direction pa-0">
                        <v-autocomplete
                          label="كود البلد"
                          :rules="[validationRules.required]"
                          :items="Codes"
                          required
                          @change="hideLabel = true"
                          v-model="country_id"
                          item-text="title"
                          item-value="value"
                          disabled
                        >
                          <!-- v-model="editedItem.country_code_id" -->
                          <template slot="selection" slot-scope="data">
                            <!-- <img src="../../assets/flags/AF.png" />
                    {{ data.title }}-->

                            <v-flex xs3>
                              <v-avatar size="25">
                                <img :src="data.item.icon" />
                              </v-avatar>
                            </v-flex>
                            <v-flex class="ml-3">{{ data.item.title }}</v-flex>
                          </template>
                          <template slot="item" slot-scope="data">
                            <v-list-item-avatar
                              style="width: 20px; min-width: 20px; height: 20px"
                            >
                              <img :src="data.item.icon" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="data.item.title"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="">
                  <v-col cols="12" class="pa-0 text-left my-12">
                    <v-btn
                      class="white--text main-btn login-btn"
                      color="#a53860"
                      @click="sendCode()"
                      >ارسال كود التحقق
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
            <div class="step-2" v-if="step == 2">
              <h2 class="mb-4 form-title">
                <strong> التحقق</strong>
              </h2>

              <div
                class="validation error--text mt-4 text-center"
                v-if="validation_errors"
              >
                <p>
                  {{ validation_errors }}
                </p>
              </div>
              <p v-if="confirmMsg" class="text-center mb-4">{{ confirmMsg }}</p>
              <v-form v-model="valid" ref="form" class="mt-12">
                <v-row>
                  <v-col cols="12">
                    <v-row class="d-flex align-baseline">
                      <v-col class="pa-0">
                        <v-text-field
                          label="كود التحقق"
                          name="mobile"
                          v-model="verifyCode"
                          :rules="[validationRules.required]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="">
                  <v-col cols="12" class="pa-0 text-left">
                    <v-btn
                      class="white--text main-btn login-btn my-8"
                      color="#a53860"
                      large
                      @click="checkVerifyCode()"
                      >تأكيد
                    </v-btn>
                  </v-col>
                  <v-col cols="12" class="pa-0 mt-8">
                    <div style="height: 60px"></div>
                    <p>
                      <span class="resend" @click="resend()"
                        ><strong> إعادة إرسال الكود؟</strong></span
                      >
                    </p>
                  </v-col>
                </v-row>
              </v-form>
            </div>

            <div class="step-3" v-if="step == 3">
              <h2 class="form-title">
                <strong>اعادة تعيين كلمة المرور</strong>
              </h2>

              <div
                class="validation error--text mt-4 text-center"
                v-if="validation_errors"
              >
                <p>
                  {{ validation_errors }}
                </p>
              </div>
              <p v-if="confirmMsg" class="text-center">{{ confirmMsg }}</p>
              <v-form v-model="valid" ref="form" class="my-4">
                <v-row>
                  <v-col cols="12">
                    <v-row class="d-flex align-baseline">
                      <v-col cols="12" class="pa-0">
                        <v-text-field
                          label="كلمة المرور"
                          name="password"
                          v-model="password"
                          :rules="[
                            validationRules.required,
                            validationRules.spaces,
                            validationRules.password,
                          ]"
                          type="password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="pa-0">
                        <v-text-field
                          label="تاكيد كلمة المرور"
                          name="password"
                          v-model="confirmPassword"
                          :rules="[
                            validationRules.required,
                            confirmPass(password, confirmPassword),
                          ]"
                          type="text"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>

                <v-row class="">
                  <v-col cols="12" class="pa-0 text-left">
                    <v-btn
                      class="white--text main-btn login-btn"
                      color="#a53860"
                      @click="changePassword()"
                      >حفظ
                    </v-btn>

                    <div style="height: 60px"></div>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "../mixins/validationMixin";
import formHeader from "../components/formHeader";

import { mapGetters } from "vuex";
export default {
  name: "resetPassword",
  mixins: [validationMixin],
  components: { formHeader },
  data() {
    return {
      show1: false,
      Codes: [],
      step: 1,
      valid: false,
      validation_errors: "",
      mobile: "",
      country_id: 187,
      verifyCode: "",
      password: "",
      confirmPassword: "",

      confirmMsg: "",
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    sendCode() {
      this.validation_errors = "";
      if (this.valid) {
        this.$http
          .post(this.base_url + "/front/user/password/forget", {
            mobile: this.mobile,
          })
          .then((response) => {
            if (response.data.status.error) {
              this.validation_errors = response.data.status.message;
            } else {
              this.confirmMsg = response.data.status.message;
              this.step = 2;
              this.valid = false;
              this.validation_errors = "";
              this.$refs.form.resetValidation();
            }
          });
      } else {
        this.validate();
      }
    },
    checkVerifyCode() {
      this.validation_errors = "";
      this.confirmMsg = "";
      if (this.valid) {
        const verifyObj = {
          mobile: this.mobile,
          verification_code: this.verifyCode,
        };
        this.$http
          .post(this.base_url + "/front/user/password/check", verifyObj)
          .then((response) => {
            if (response.data.status.error) {
              this.validation_errors = response.data.status.message;
            } else {
              console.log(response.data);
              this.step = 3;
              this.valid = false;
              this.validation_errors = "";
              this.$refs.form.resetValidation();
            }
          });
      } else {
        this.validate();
      }
    },
    changePassword() {
      this.validation_errors = "";
      this.confirmMsg = "";
      if (this.valid) {
        const verifyObj = {
          mobile: this.mobile,
          verification_code: this.verifyCode,
          password: this.password,
        };
        this.$http
          .post(this.base_url + "/front/user/changepassword", verifyObj)
          .then((response) => {
            if (response.data.status.error) {
              this.validation_errors = response.data.status.message;
            } else {
              this.$refs.form.resetValidation();
              this.confirmMsg = "تم تغير الباسورد بنجاح";
              setTimeout(() => {
                window.location.href = "/";
              }, 2000);
            }
          });
      } else {
        this.validate();
      }
    },

    resend() {
      this.validation_errors = "";
      this.$http
        .post(this.base_url + "/front/verify/resend", {
          mobile: this.mobile,
          forget: true,
        })
        .then((response) => {
          if (response.data.status.error) {
            this.validation_errors = response.data.status.message;
          } else {
            console.log("send");
            this.confirmMsg = response.data.status.message;
          }
        });
    },
    getCountryCodes() {
      this.$http.get(this.base_url + "/front/getCodes").then((response) => {
        this.Codes = response.data.data;
        console.log(this.Codes);
      });
    },
    confirmPass(pass, confirm) {
      if (pass == confirm) {
        return true;
      } else {
        return "غير متصابق";
      }
    },
  },
  mounted() {
    this.getCountryCodes();
    const token = localStorage.getItem("token");
    if (token) {
      window.location.href = "/";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";
.main-btn {
  width: 150px !important;
}
.header {
  margin-top: 50px;
  margin-right: 50px;
  margin-left: 50px;
}
.content {
  // height: 100%;
  .title,
  .brand-name {
    font-size: 1.5rem !important;
  }
  .location {
    font-size: 1rem;
  }
  // background-color: #e5e5e5;
  // padding-bottom: 50px;
  //   height: 50vh;
  .form-card {
    width: 900px;
    // height: 560px;
    // overflow: auto;
    margin: auto;
    margin-bottom: 1rem;
    // position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    // position: relative;
    // margin-top: -150px !important;
    .right-col {
      // height: 630px;
      .form-title {
        font-weight: bold;
        font-size: 2rem;
      }
      border-top: 5px solid #a53860;
      border-radius: 0 0 0 70px;
      padding: 1rem 3rem;
      background-color: #fff;
      margin-bottom: 10px !important;
      padding-top: 50px;
      padding-bottom: 50px;
    }
    .left-col {
      // height: 630px;
      border-bottom: 5px solid #fff;
      border-radius: 0 70px 0 0;
      padding: 1rem 2rem;
      background-color: #2d3533;
      margin-top: 10px;

      .slogan {
        position: absolute;
        top: 40%;
        right: 6%;
        font-size: 2rem;
        font-weight: bold;
        .slogan1 {
          margin-bottom: 2rem;
        }
        .slogan2 {
        }
      }
    }
    .bottom-img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 150px;
      opacity: 0.5;
    }
  }
}

.resend {
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  // text-decoration: underline;
}
@media (min-width: 960px) and (max-width: 1264px) {
  .form-card {
    max-width: 80%;
    margin-top: 10rem !important;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .right-col {
    padding: 1rem !important;
  }
  .left-col {
    .slogan {
      top: 40%;
      right: 6%;
      font-size: 1.5rem !important;
    }
  }
  .form-card {
    max-width: 90%;
    margin-top: 2rem !important;
  }
}
@media (max-width: 600px) {
  .right-col {
    padding: 1rem 2rem !important;
  }
  .form-card {
    max-width: 80%;
  }
}
</style>
