<template>
  <div class="reservation">
    <formHeader />
    <div class="content">
      <v-card class="mx-auto form-card">
        <v-row>
          <v-col cols="12" sm="6" class="left-col d-none d-sm-block">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" class="text-center">
                  <div class="slogan">
                    <h2 class="slogan1 white--text">انضم لاكبر مجتمع</h2>
                    <h2 class="slogan2 second-color">تجميل فى العالم</h2>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
          <v-col cols="12" sm="6" class="right-col mt-4 mt-sm-0">
            <div v-if="!showVerifyCode">
              <h2 class="mb-4 reg-title"><strong> تسجيل الصالون</strong></h2>
              <div class="login-sec mb-8">
                <p>لديك حساب بالفعل ؟</p>
                <v-btn
                  class="white--text main-btn login-btn"
                  color="#a53860"
                  to="/login"
                  >تسجيل الدخول</v-btn
                >
              </div>

              <div class="validation error--text mt-4 text-center">
                <p v-if="validation_errors">
                  {{ validation_errors }}
                </p>
              </div>
              <v-form v-model="valid" ref="form" class="my-4">
                <v-row>
                  <v-col cols="12" class="pa-0">
                    <v-row class="d-flex align-baseline">
                      <v-col class="pa-0" cols="6" sm="8">
                        <v-text-field
                          label="رقم الجوال"
                          v-model="register_fields.mobile"
                          :rules="[
                            validationRules.required,
                            validationRules.spaces,
                            mobileRule(register_fields.mobile),
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="6" sm="4" class="ltr-direction pa-0">
                        <!-- <div id="country-code"> -->
                        <v-autocomplete
                          label="كود البلد"
                          :rules="[validationRules.required]"
                          :items="Codes"
                          required
                          @change="hideLabel = true"
                          v-model="register_fields.country_id"
                          item-text="title"
                          item-value="value"
                          disabled
                        >
                          <!-- v-model="editedItem.country_code_id" -->
                          <template slot="selection" slot-scope="data">
                            <!-- <img src="../../assets/flags/AF.png" />
                    {{ data.title }}-->

                            <v-flex xs3>
                              <v-avatar size="25">
                                <img :src="data.item.icon" />
                              </v-avatar>
                            </v-flex>
                            <v-flex class="ml-3">{{ data.item.title }}</v-flex>
                          </template>
                          <template slot="item" slot-scope="data">
                            <v-list-item-avatar
                              style="width: 20px; min-width: 20px; height: 20px"
                            >
                              <img :src="data.item.icon" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title
                                v-html="data.item.title"
                              ></v-list-item-title>
                            </v-list-item-content>
                          </template>
                        </v-autocomplete>
                        <!-- </div> -->

                        <!-- <div v-if="validation_errors.country_code_id">
                        <p
                          class="red--text"
                          v-for="(
                            error, index
                          ) in validation_errors.country_code_id"
                          :key="index"
                        >
                          {{ error }}
                        </p>
                      </div> -->
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" class="pa-0">
                    <v-text-field
                      label="كلمة المرور"
                      v-model="register_fields.password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="[
                        validationRules.required,
                        validationRules.spaces,
                        validationRules.password,
                      ]"
                      :type="show1 ? 'text' : 'password'"
                      @click:append="show1 = !show1"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row class="text-left my-8">
                  <v-col class="pa-0">
                    <v-btn
                      class="white--text main-btn login-btn"
                      color="#a53860"
                      @click="register()"
                      large
                      >تسجيل
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </div>
            <verifyRegister
              :mobile="register_fields.mobile"
              :country_id="register_fields.country_id"
              :message="confirmMsg"
              type="salon"
              v-else
            />
          </v-col>
        </v-row>
      </v-card>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "../mixins/validationMixin";
import verifyRegister from "../components/verifyRegister.vue";
import formHeader from "../components/formHeader";

import { mapGetters } from "vuex";
export default {
  name: "registrationAsSalon",
  mixins: [validationMixin],
  components: { verifyRegister, formHeader },
  data() {
    return {
      show1: false,
      valid: false,
      Codes: [],
      validation_errors: "",
      showVerifyCode: false,
      register_fields: {
        mobile: "",
        country_id: 187,
        password: "",
      },
      confirmMsg: "",
    };
  },
  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    getCountryCodes() {
      this.$http.get(this.base_url + "/front/getCodes").then((response) => {
        this.Codes = response.data.data;
        console.log(this.Codes);
      });
    },
    register() {
      this.validation_errors = "";
      if (this.valid) {
        this.$http
          .post(this.base_url + "/register", this.register_fields)
          .then((response) => {
            if (response.data.status.error) {
              this.validation_errors = response.data.status.message;
            } else {
              this.showVerifyCode = true;
              this.confirmMsg = response.data.status.message;
            }
          });
      } else {
        this.validate();
      }
    },
    confirmPass(pass, confirm) {
      if (pass == confirm) {
        return true;
      } else {
        return "غير متصابق";
      }
    },
  },
  mounted() {
    this.getCountryCodes();
    const token = localStorage.getItem("token");
    if (token) {
      window.location.href = "/";
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";
.main-btn {
  width: 150px !important;
}
.header {
  margin-top: 50px;
  margin-right: 50px;
  margin-left: 50px;
}
.content {
  // height: 100%;
  .title,
  .brand-name {
    font-size: 1.5rem !important;
  }
  .location {
    font-size: 1rem;
  }
  // background-color: #e5e5e5;
  // padding-bottom: 50px;
  //   height: 50vh;
  .form-card {
    width: 900px;
    // height: 560px;
    // overflow: auto;
    margin: auto;
    margin-bottom: 1rem;
    // position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    // position: relative;
    // margin-top: -150px !important;
    .right-col {
      // height: 630px;
      border-top: 5px solid #a53860;
      border-radius: 0 0 0 70px;
      padding: 1rem 3rem;
      background-color: #fff;
      margin-bottom: 10px !important;
      .reg-title {
        font-weight: bold;
        font-size: 2rem;
      }
    }
    .left-col {
      // height: 630px;
      border-bottom: 5px solid #fff;
      border-radius: 0 70px 0 0;
      padding: 1rem 2rem;
      background-color: #2d3533;
      margin-top: 10px;

      .slogan {
        position: absolute;
        top: 40%;
        right: 6%;
        font-size: 2rem;
        .slogan1 {
          margin-bottom: 3rem;
        }
        .slogan2 {
        }
      }
    }
    .bottom-img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 150px;
      opacity: 0.5;
    }
  }
}
@media (min-width: 960px) and (max-width: 1264px) {
  .form-card {
    max-width: 80%;
    margin-top: 10rem !important;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .right-col {
    padding: 1rem !important;
  }
  .left-col {
    .slogan {
      top: 40%;
      right: 6%;
      font-size: 1.5rem !important;
    }
  }
  .form-card {
    max-width: 90%;
    margin-top: 2rem !important;
  }
}

@media (max-width: 600px) {
  .right-col {
    padding: 1rem 2rem !important;
  }
  .form-card {
    max-width: 80%;
  }
}
</style>
