<template>
  <div class="verify_code">
    <h2><strong> التحقق </strong></h2>
    <div class="login-sec mt-4">
      <p>{{ message }}</p>
    </div>
    <div class="validation error--text mt-4 text-center">
      <p>
        {{ validation_errors }}
      </p>
    </div>
    <v-form v-model="valid" ref="form" class="my-4">
      <v-row>
        <v-col cols="12" class="pa-0 mt-8">
          <v-text-field
            label="كود التحقق"
            v-model="verifyObj.verification_code"
            :rules="[validationRules.required, validationRules.spaces]"
          ></v-text-field>
        </v-col>
        <v-col cols="12" class="pa-0 text-left">
          <v-btn
            class="white--text main-btn login-btn"
            color="#a53860"
            @click="checkVerifyCode()"
            >تأكيد
          </v-btn>
        </v-col>

        <v-col cols="12" class="pa-0">
          <div style="height: 200px"></div>
          <p>
            لم تصلك الرسالة ؟
            <span class="resend" @click="resend()"
              ><strong> اعادة ارسال كود التحقق </strong></span
            >
          </p>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { validationMixin } from "../mixins/validationMixin";
import { mapGetters } from "vuex";

export default {
  name: "verifyRegister",
  mixins: [validationMixin],
  props: ["mobile", "message", "country_id", "type"],

  data() {
    return {
      valid: false,
      verifyObj: {
        verification_code: "",
        mobile: "",
        country_id: "",
      },
      validation_errors: "",
    };
  },

  computed: {
    ...mapGetters(["base_url"]),
  },
  methods: {
    checkVerifyCode() {
      let url = "";
      if (this.type == "salon") {
        url = "/verify";
      } else {
        url = "/front/verify";
      }
      this.validation_errors = "";
      this.$http.post(this.base_url + url, this.verifyObj).then((response) => {
        if (response.data.status.error) {
          this.validation_errors = response.data.status.message;
        } else {
          window.location.href = "/login";
        }
      });
    },
    resend() {
      let url = "";
      if (this.type == "salon") {
        url = "/verify/resend";
      } else {
        url = "/front/verify/resend";
      }
      this.validation_errors = "";
      this.$http
        .post(this.base_url + url, {
          mobile: this.verifyObj.mobile,
        })
        .then((response) => {
          if (response.data.status.error) {
            this.validation_errors = response.data.status.message;
          } else {
            console.log("send");
          }
        });
    },
  },
  mounted() {
    this.verifyObj.mobile = this.mobile;
    this.verifyObj.country_id = this.country_id;
  },
};
</script>

<style lang="scss" scoped>
.verify_code {
  height: 500px;
}

.resend {
  font-weight: bold;
  cursor: pointer;
  display: inline-block;
  // text-decoration: underline;
}
</style>
>
