import moment from "moment";
export const validationMixin = {
  data() {
    return {
      // vuetify validation
      validationRules: {
        required: (v) => !!v || "هذا الحقل مطلوب",

        maxLength2: (v) => {
          if (v) {
            return (
              v.length <= 2 ||
              "You have reached your maximum limit of characters allowed"
            );
          } else return true;
        },
        maxLength4: (v) => {
          if (v) {
            return (
              v.length <= 4 ||
              "You have reached your maximum limit of characters allowed"
            );
          } else return true;
        },
        maxLength5: (v) => {
          if (v) {
            return (
              v.length <= 5 ||
              "You have reached your maximum limit of characters allowed"
            );
          } else return true;
        },
        maxLength50: (v) => {
          if (v) {
            return (
              v.length <= 50 ||
              "You have reached your maximum limit of characters allowed"
            );
          } else return true;
        },
        maxLength25: (v) => {
          if (v)
            return (
              v.length <= 25 ||
              "You have reached your maximum limit of characters allowed"
            );
          else return true;
        },
        maxLength16: (v) => {
          if (v) return v.length == 16 || "يجب ان يكون 16 رقم فقط";
          else return true;
        },
        maxLength20: (v) => {
          if (v)
            return (
              v.length <= 20 ||
              "You have reached your maximum limit of characters allowed"
            );
          else return true;
        },
        maxLength3: (v) => {
          if (v) return (v && v.length == 3) || "يجب ان يكون ثلاثة ارقام فقط";
          else return true;
        },
        // numeric: v =>
        //   (v && /^\d+$/.test(v)) || "This field only accept numbers",
        numeric: (v) => {
          if (v) return (v && /^\d+$/.test(v)) || "يجب ان يكون ارقام فقط";
          else return true;
        },
        percentage: (v) =>
          (v > 0 && v <= 100) || "The percentage must be between 0 and 100.",
        alphaNum: (v) =>
          (v && /^\w+$/.test(v)) || "Please enter a valid format",
        email: (v) => /.+@.+\..+/.test(v) || "البريد الالكترونى غير صحيح",
        phoneNum: (v) => /^(\+?)[0-9]+$/.test(v) || "ادخل رقم جوال صحيح",
        notStartWithZero: (v) =>
          (v.length == 9 && v[0] != 0) ||
          "This field must be only 9 numbers and not start with 0",
        spaces: (v) =>
          /^(.*[^ ].*)/.test(v) || "لا يجب ان يحتوى الحقل على مسافات فقط",
        password: (v) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(v) ||
          "كلمة المرور يجب ان يحتوى على 8 رموز ويحتوى على حروف كبيره وصغيره",
        imageValidation: (v) =>
          v.size < 2000000 || "Avatar size should be less than 2 MB!",
        minOne: (v) => v >= 1 || "minOneValidation",
      },
    };
  },

  methods: {
    onlinePaymentValidation(cardNumber) {
      if (cardNumber) {
        if (
          /^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/.test(
            cardNumber
          ) ||
          /^4[0-9]{12}(?:[0-9]{3})?$/.test(cardNumber)
        ) {
          return true;
        } else {
          return "يجب ادخال رقم بطاقة صحيح";
        }
      }
    },
    validate(tab = 0) {
      if (tab == 0) {
        this.$refs.form.validate();
      } else if (tab == 1) {
        this.$refs.form1.validate();
      } else if (tab == 2) {
        this.$refs.form2.validate();
      } else if (tab == 3) {
        this.$refs.form3.validate();
      } else if (tab == 4) {
        this.$refs.form4.validate();
      }
    },
    checkMultiSelect(selectArray) {
      return selectArray.length == 0 ? "required" : true;
    },
    checkboxValidation(checkboxArray) {
      return checkboxArray.length == 0 ? "required" : true;
    },

    mobileRule(mobile) {
      return !/^([0-9])*$/.test(mobile) ||
        mobile.length <= 5 ||
        mobile.length >= 15
        ? "رقم الهاتف يجب ان يكون ما بين 5 ارقام الى 15 رقماً"
        : true;
    },

    checkDate(year, month) {
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;

      if (year && year <= currentYear && month <= currentMonth) {
        return "برجاء ادخال تاريخ بطاقة صالحة";
      } else {
        return true;
      }
    },

    startTimeRule(start, end, str) {
      var startTime = moment(start, "HH:mm");
      var endTime = moment(end, "HH:mm");
      return startTime >= endTime ? str : true;
      // console.log("error");
    },
  },
};
