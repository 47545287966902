<template>
  <div class="reservation">
    <div class="header">
      <v-img src="../assets/logo.png" class="logo" width="200px"></v-img>
    </div>
    <div class="content">
      <v-card class="mx-auto form-card">
        <v-row>
          <v-col cols="12" sm="6" class="right-col">
            <v-card-text>
              <v-row class="mx-0">
                <v-col class="text-right" style="padding-right: 0" sm="12">
                  <h1 class="mb-4 white-text mb-5">استمارة طلب موعد</h1>
                  <p class="error--text text-center" v-if="validationMsg">
                    {{ validationMsg }}
                  </p>
                </v-col>
                <v-col class="text-right" style="padding-right: 0" sm="11">
                  <h3 class="mb-2 brand-name white-text">{{ salon }}</h3>
                  <p class="gray-text location mb-0">{{ branch }}</p>
                </v-col>
                <v-col class="text-right" style="padding-right: 0" sm="1">
                  <v-icon large class="gray-text"> mdi-map-marker </v-icon>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-form v-model="valid" ref="form">
                <v-row>
                  <v-col cols="12" sm="12" class="py-0 my-0">
                    <!-- <label
                  for="history"
                  class="white-text mb-3 mr-4 d-inline-block"
                >
                  التاريخ
                </label> -->
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      :return-value.sync="data.reservation_date"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.reservation_date"
                          label="التاريخ"
                          class="rounded-input"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[
                            validationRules.required,
                            validationRules.spaces,
                          ]"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="data.reservation_date"
                        no-title
                        scrollable
                        :min="nowDate"
                        :allowed-dates="allowedDates"
                      >
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="menu = false">
                          إلغاء
                        </v-btn>
                        <v-btn text color="primary" @click="saveDate">
                          موافق
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                    <p class="white-text text-center">{{ available }}</p>
                  </v-col>
                  <v-col cols="12" sm="12" class="py-0 my-0">
                    <!-- <label
                  for="history"
                  class="white-text mb-3 mr-4 d-inline-block"
                >
                  زمن الحجز
                </label> -->
                    <v-menu
                      ref="menu2"
                      v-model="menu2"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      :return-value.sync="data.reservation_time"
                      transition="scale-transition"
                      offset-y
                      max-width="290px"
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="data.reservation_time"
                          label="الزمن"
                          class="rounded-input"
                          append-icon="mdi-clock-time-four-outline"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="[
                            validationRules.required,
                            validationRules.spaces,
                          ]"
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-if="menu2"
                        v-model="data.reservation_time"
                        full-width
                        @click:minute="$refs.menu2.save(data.reservation_time)"
                        :allowed-hours="allowedHours"
                        :allowed-minutes="allowedMinutes"
                        :disabled="data.reservation_date === ''"
                      ></v-time-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="12" class="py-0 my-0">
                    <!-- <label
                  for="history"
                  class="white-text mb-3 mr-4 d-inline-block"
                >
                  إسم الزبون
                </label> -->
                    <v-text-field
                      label="إسم الزبون"
                      class="rounded-input"
                      v-model="data.full_name"
                      append-icon="mdi-account"
                      :rules="[
                        validationRules.required,
                        validationRules.spaces,
                      ]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" class="py-0 my-0">
                    <!-- <label
                  for="history"
                  class="white-text mb-3 mr-4 d-inline-block"
                >
                  رقم الجوال
                </label> -->
                    <v-row>
                      <v-col cols="12" sm="9" class="py-0 my-0">
                        <v-text-field
                          label="رقم الجوال"
                          class="rounded-input"
                          append-icon="mdi-phone"
                          v-model="data.mobile"
                          :rules="[
                            validationRules.required,
                            validationRules.spaces,
                            mobileRule(data.mobile),
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="3" class="py-0 my-0 countrycode-col">
                        <v-text-field
                          v-model="countrycode"
                          class="rounded-input countrycode"
                          disabled
                        >
                        </v-text-field>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col class="text-right" sm="11">
                        <v-label>الخدمات</v-label>
                      </v-col>
                      <v-col class="text-right" style="padding-right: 0" sm="1">
                        <v-img
                          style="width: 20px"
                          src="../assets/small-logo.png"
                        />
                      </v-col>
                      <v-col>
                        <v-card class="mx-auto reserve-card" outlined>
                          <div
                            class="card-head d-flex"
                            style="justify-content: center"
                            v-if="
                              Object.keys(services.available_at_home).length > 0
                            "
                          >
                            <span
                              class="mb-0 mx-1 mt-1 font-16"
                              :class="
                                !availableAtHome ? 'main-color' : 'switch-label'
                              "
                              >حجز بالصالون</span
                            >
                            <v-switch
                              color="#A53860"
                              v-model="availableAtHome"
                              label=""
                            ></v-switch>
                            <span
                              class="mb-0 mx-1 mt-1 font-16"
                              :class="
                                availableAtHome ? 'main-color' : 'switch-label'
                              "
                              >حجز بالمنزل</span
                            >
                          </div>
                          <v-card-text v-if="!availableAtHome">
                            <v-expansion-panels>
                              <v-expansion-panel
                                v-for="(
                                  items, index
                                ) in services.available_at_salon"
                                :key="index"
                              >
                                <v-expansion-panel-header>
                                  {{ index }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <div v-for="(item, i) in items" :key="i">
                                    <v-checkbox
                                      v-model="selectedSessions"
                                      multiple
                                      :label="item.name"
                                      color="#A53860"
                                      :value="item.id"
                                      hide-details
                                      :class="
                                        selectedSessions.includes(item.id)
                                          ? 'main-color'
                                          : 'switch-label'
                                      "
                                    ></v-checkbox>
                                    <v-row
                                      v-if="selectedSessions.includes(item.id)"
                                    >
                                      <v-col sm="10" class="px-0">
                                        <p class="mr-10 mb-0">
                                          <span>السعر</span>
                                          <span class="d-inline-block mx-4">
                                            |
                                          </span>
                                          <span>الفترة الزمنية</span>
                                        </p>
                                        <v-row class="price-hour-div">
                                          <v-col
                                            sm="3"
                                            style="margin-left: 15px"
                                          >
                                            <v-text-field
                                              class="mr-8 main-color"
                                              label=""
                                              :value="item.price + ' ريال'"
                                              readonly
                                            ></v-text-field>
                                          </v-col>
                                          <v-col
                                            :sm="
                                              item.hours && item.minutes
                                                ? '6'
                                                : '4'
                                            "
                                          >
                                            <v-text-field
                                              label=""
                                              :value="
                                                (item.hours
                                                  ? item.hours + ' ساعات'
                                                  : '') +
                                                ' ' +
                                                (item.minutes
                                                  ? item.minutes + ' دقائق'
                                                  : '')
                                              "
                                              readonly
                                              class="main-color"
                                              style="text-align: center"
                                            ></v-text-field>
                                          </v-col>
                                        </v-row>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-card-text>
                          <v-card-text v-else>
                            <v-expansion-panels>
                              <v-expansion-panel
                                v-for="(
                                  items, index
                                ) in services.available_at_home"
                                :key="index"
                              >
                                <v-expansion-panel-header>
                                  {{ index }}
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                  <div v-for="(item, i) in items" :key="i">
                                    <v-checkbox
                                      multiple
                                      v-model="selectedSessions"
                                      :label="item.name"
                                      color="#A53860"
                                      :value="item.id"
                                      hide-details
                                      :class="
                                        selectedSessions.includes(item.id)
                                          ? 'main-color'
                                          : 'switch-label'
                                      "
                                    ></v-checkbox>
                                    <v-row
                                      class="price-hour-div"
                                      v-if="selectedSessions.includes(item.id)"
                                    >
                                      <v-col sm="3" style="margin-left: 15px">
                                        <v-text-field
                                          class="mr-8 main-color"
                                          label=""
                                          :value="item.price + ' ريال'"
                                          readonly
                                        ></v-text-field>
                                      </v-col>
                                      <v-col
                                        :sm="
                                          item.hours && item.minutes ? '6' : '4'
                                        "
                                      >
                                        <v-text-field
                                          label=""
                                          :value="
                                            (item.hours
                                              ? item.hours + ' ساعات'
                                              : '') +
                                            ' ' +
                                            (item.minutes
                                              ? item.minutes + ' دقائق'
                                              : '')
                                          "
                                          readonly
                                          class="main-color"
                                          style="text-align: center"
                                        ></v-text-field>
                                      </v-col>
                                    </v-row>
                                  </div>
                                </v-expansion-panel-content>
                              </v-expansion-panel>
                            </v-expansion-panels>
                          </v-card-text>
                        </v-card>
                        <div
                          class="v-text-field__details mt-4"
                          v-if="sessionMessage != ''"
                        >
                          <div
                            class="v-messages theme--light error--text"
                            role="alert"
                          >
                            <div class="v-messages__wrapper">
                              <div class="v-messages__message">
                                {{ sessionMessage }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                    <div
                      class="mt-2"
                      @click="addressDialog = true"
                      v-if="availableAtHome"
                      style="cursor: pointer"
                    >
                      <v-icon medium color="#a53860"> mdi-map-marker </v-icon>
                      <small
                        style="color: #a53860; font-size: 13px"
                        v-if="availableAtHome && !validAddress"
                        >برجاء ادخال عنوان المنزل</small
                      >
                      <small style="color: #a53860; font-size: 13px" v-else
                        >تعديل عنوان المنزل</small
                      >
                    </div>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions class="text-right mt-4 d-block">
              <v-btn
                class="main-btn"
                large
                @click="completeReservation"
                style="letter-spacing: 0"
                >حــجــز</v-btn
              >
            </v-card-actions>
          </v-col>
          <v-col cols="12" sm="6" class="left-col">
            <!-- :position="google && new google.maps.LatLng(1.38, 103.8)" -->
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="12" class="text-center" v-if="showMap">
                  <GmapMap
                    :center="center"
                    :zoom="7"
                    ref="mapRef"
                    style="
                      width: 100%;
                      height: 700px;
                      margin: 0 auto;
                      border-radius: 20px;
                    "
                    :options="{
                      zoomControl: true,
                      mapTypeControl: true,
                      scaleControl: true,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: true,
                      disableDefaultUi: false,
                    }"
                  >
                    <gmap-marker
                      :key="index"
                      v-for="(m, index) in markers"
                      :position="m.position"
                      :clickable="true"
                      :draggable="true"
                      @click="center = m.position"
                    ></gmap-marker>
                  </GmapMap>
                </v-col>
              </v-row>
            </v-card-text>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <!-- address dialog -->
    <v-dialog
      v-model="availableAtHome"
      v-if="addressDialog"
      persistent
      max-width="450px"
      class="address-dialog"
    >
      <v-card>
        <v-card-title>
          <h3 class="gray-text">أضف عنوان المنزل</h3>
          <v-btn
            icon
            color="#a53860"
            @click="addressDialog = false"
            class="close-icon"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text style="padding-bottom: 0">
          <v-container style="padding-bottom: 0">
            <v-form v-model="validAddress" ref="addressForm">
              <v-row>
                <v-col cols="12" sm="12">
                  <Map
                    ref="myMap"
                    v-on:updateLatLng="getLatLng"
                    :editCoordinates="editCoordinates"
                  ></Map>
                </v-col>
              </v-row>
              <v-row style="margin-top: 0; margin-bottom: 10px">
                <v-col cols="12" sm="12" class="mb-2">
                  <small style="color: #a53860; font-size: 13px"
                    >يجب تحديد موقع المنزل على الخريطة</small
                  >
                </v-col>
                <v-col cols="12" sm="12" style="text-align: center">
                  <v-btn
                    class=""
                    small
                    @click="getCurrentPosition"
                    style="
                      letter-spacing: 0;
                      background-color: #a53860;
                      color: #fff;
                    "
                    >إختيار الموقع الحالي تلقائياً</v-btn
                  >
                  <br />
                  <small style="color: #a53860; font-size: 11px"
                    >يتطلب السماح باستخدام GPS</small
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12">
                  <v-select
                    :items="[{ id: 187, name: 'المملكة العربية السعودية' }]"
                    v-model="data.location.country_id"
                    item-text="name"
                    item-value="id"
                    label="الدولة"
                    required
                    solo
                    class="rounded-input"
                    disabled
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12">
                  <v-select
                    :items="cities"
                    v-model="data.location.city_id"
                    label="المدينة"
                    item-text="name"
                    item-value="id"
                    :rules="[validationRules.required]"
                    solo
                    class="rounded-input"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="إسم الحي"
                    class="rounded-input"
                    v-model="data.location.district"
                    :rules="[validationRules.required, validationRules.spaces]"
                    solo
                    aria-autocomplete="false"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="إسم الشارع"
                    class="rounded-input"
                    v-model="data.location.street"
                    :rules="[validationRules.required, validationRules.spaces]"
                    solo
                    aria-autocomplete="false"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-text-field
                    label="الرمز البريدي"
                    class="rounded-input"
                    v-model="data.location.zip_code"
                    :rules="[validationRules.required, validationRules.spaces]"
                    solo
                    aria-autocomplete="false"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="1"> </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    label="رقم إضافي"
                    class="rounded-input"
                    v-model="data.location.additional_number"
                    :rules="[validationRules.required, validationRules.spaces]"
                    solo
                    aria-autocomplete="false"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <div class="text-center mx-auto py-3">
          <v-btn
            class="save-address-btn main-btn"
            text
            @click="saveAddress"
            style="letter-spacing: 0"
          >
            حفظ
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "../mixins/validationMixin";
import * as VueGoogleMaps from "vue2-google-maps";
import Map from "../components/google-maps.vue";
export default {
  name: "Reservation",
  mixins: [validationMixin],
  data() {
    return {
      editCoordinates: { lat: null, lng: null },
      availableAtHome: false,
      checked: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      time: null,
      valid: false,
      validAddress: false,
      addressDialog: false,
      cities: [],
      menu: false,
      menu2: false,
      salon: "",
      branch: "",
      selectedSessions: [],
      data: {
        reservation_date: "",
        reservation_time: null,
        full_name: "",
        mobile: "",
        sessions: [],
        destination: this.availableAtHome ? "home" : "salon",
        location: {
          district: "",
          street: "",
          zip_code: "",
          additional_number: "",
          country_id: 187,
          city_id: "",
          lat: "",
          lng: "",
        },
      },
      countrycode: "+966",
      nowDate: new Date(Date.now()).toISOString().slice(0, 10),
      weekDays: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      allowedDays: [],
      allowedDates: (val) =>
        this.allowedDays.includes(this.weekDays[new Date(val).getDay()]),
      hours: [],
      allowedHours: (h) => this.hours.includes(h),
      allowedMinutes: (m) => m % 30 === 0,
      available: "",
      services: [],
      center: { lat: 24.7136, lng: 46.6753 },
      markers: [
        {
          position: { lat: 24.7136, lng: 46.6753 },
        },
      ],
      showMap: false,
      sessionMessage: "",
      branchIsActive: true,
      validationMsg: "",
    };
  },
  methods: {
    getCurrentPosition() {
      this.$refs.myMap.getCurrentPosition();
    },
    getBranchData() {
      this.$http
        .get(
          this.base_url +
            "/salon/branches/" +
            this.$route.params.slug +
            "/show",

          {}
        )
        .then((response) => {
          console.log(response);
          if (response.data.data == null) {
            this.$router.push({ path: "/404" });
          } else {
            this.salon = response.data.data.branch.salon.name;
            this.branch = response.data.data.branch.name;
            this.branchIsActive =
              response.data.data.branch.active == 1 ? true : false;

            if (this.branchIsActive == false) {
              window.location.href = "/404";
            }
            this.center = {
              lat: response.data.data.branch.lat
                ? parseFloat(response.data.data.branch.lat)
                : 24.7136,
              lng: response.data.data.branch.lng
                ? parseFloat(response.data.data.branch.lng)
                : 46.6753,
            };

            this.markers[0].position.lat = response.data.data.branch.lat
              ? parseFloat(response.data.data.branch.lat)
              : 24.7136;
            this.markers[0].position.lng = response.data.data.branch.lng
              ? parseFloat(response.data.data.branch.lng)
              : 46.6753;
            this.allowedDays = response.data.data.allowedDays;
            this.showMap = true;
          }
        });
    },
    getServices() {
      this.$http
        .get(
          this.base_url + "/salon/services/" + this.$route.params.slug,

          {}
        )
        .then((response) => {
          console.log(response);
          this.services = response.data.data;
        });
    },
    getCities() {
      this.$http.get(this.base_url + "/salon/cities").then((response) => {
        this.cities = response.data.data.cities;
      });
    },
    completeReservation() {
      if (this.valid == false || (this.availableAtHome && !this.validAddress)) {
        this.validate();
        if (this.selectedSessions.length == 0) {
          this.sessionMessage = "يجب اختيار جلسة واحدة على الأقل";
        }
      } else {
        if (this.selectedSessions.length == 0) {
          this.sessionMessage = "يجب اختيار جلسة واحدة على الأقل";
        } else {
          this.data.sessions = this.selectedSessions;
          this.$http
            .post(
              this.base_url +
                "/salon/branches/" +
                this.$route.params.slug +
                "/reserve",
              this.data
            )
            .then((response) => {
              console.log(response);
              if (response.data.status.error == false) {
                this.$router.push({
                  path: "/confirmed/" + response.data.data.id,
                });
              } else {
                this.validationMsg = response.data.status.message;
              }
            });
        }
      }
    },
    saveDate() {
      this.$refs.menu.save(this.data.reservation_date);
      this.$http
        .get(
          this.base_url +
            "/salon/branches/" +
            this.$route.params.slug +
            "/hours?date=" +
            this.data.reservation_date
        )
        .then((response) => {
          console.log(response);
          if (response.data.status.error == false) {
            this.hours = response.data.data.hours;
            this.available = response.data.data.available;
          } else {
            //
          }
        });
    },
    saveAddress() {
      if (this.availableAtHome && this.validAddress == false) {
        this.$refs.addressForm.validate();
      } else {
        this.addressDialog = false;
      }
    },
    getLatLng(lat, lng) {
      this.data.location.lat = lat;
      this.data.location.lng = lng;
      this.editCoordinates.lat = lat;
      this.editCoordinates.lng = lng;
    },
  },
  watch: {
    selectedSessions: {
      handler() {
        this.sessionMessage =
          this.selectedSessions.length == 0
            ? "يجب اختيار جلسة واحدة على الأقل"
            : "";
      },
    },
    availableAtHome: {
      handler() {
        this.selectedSessions = [];
        this.data.destination = this.availableAtHome ? "home" : "salon";
      },
    },
  },
  mounted() {
    // alert(this.$route.params.slug);
    this.getBranchData();
    this.getServices();
    this.getCities();
  },
  computed: {
    ...mapGetters(["base_url"]),
    google: VueGoogleMaps.gmapApi,
  },
  components: {
    Map,
  },
};
</script>

<style lang="scss" scoped>
@font-face {
  font-family: "jannaLtBold";
  src: local("jannaLtBold"),
    url(../assets/fonts/JannaLTBold/JannaLTBold.ttf) format("truetype");
}
$second-color: #ff7dac;
* {
  font-family: "jannaLtBold";
}
.white-text {
  color: #000;
}
.gray-text {
  color: #888888 !important;
}
.second-text {
  color: #ff7dac;
}

.header {
  .logo {
    position: absolute;
    top: 5%;
    left: 5%;
    z-index: 33423;
  }
}
.content {
  // height: 100%;
  .title,
  .brand-name {
    font-size: 1.5rem !important;
  }
  .location {
    font-size: 1rem;
  }
  // background-color: #e5e5e5;
  // padding-bottom: 50px;
  //   height: 50vh;
  .form-card {
    width: 950px;
    // height: 560px;
    // overflow: auto;
    margin: auto;
    margin-top: 3rem;
    margin-bottom: 1rem;
    // position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: transparent !important;
    box-shadow: none !important;
    // position: relative;
    // margin-top: -150px !important;
    .right-col {
      // height: 630px;
      border-top: 5px solid #a53860;
      border-radius: 0 0 90px 0;
      padding: 1rem 2rem;
      background-color: #fff;
      color: #ffffff;
      margin-bottom: 10px !important;
    }
    .left-col {
      // height: 630px;
      border-bottom: 5px solid #fff;
      border-radius: 90px 0 0 0;
      padding: 1rem 2rem;
      background-color: #2d3533;
      color: #ffffff;
      margin-top: 10px;
    }
    .bottom-img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 150px;
      opacity: 0.5;
    }
  }
}

.rounded-input {
  border-radius: 20px;
}
.countrycode {
  direction: ltr !important;
  text-align: center !important;
  padding-right: 0 !important;
}
.countrycode-col {
  margin-right: 0 !important;
  padding-right: 0 !important;
}
.countrycode-col .v-text-field__slot input {
  text-align: center !important;
}
.main-btn {
  background-color: #a53860 !important;
  color: #ffffff !important;
  border-radius: 5px 5px 20px 5px;
  width: 150px;
  font-size: 1.2rem !important;
}

.success-content {
  .success-msg {
    font-size: 1.5rem;
    font-weight: bold;
  }
  .reg-details {
    border: 1px solid #cccccc;
    padding: 1rem 2rem;
    color: #ffffff;
    border-radius: 20px;
    ul {
      list-style: none;
    }
  }
}
.text-center {
  text-align: center !important;
}
/* card style */
.card-head {
  border-bottom: 1px solid #ccc;
}

.close-icon {
  position: absolute;
  left: 20px;
}

@media (min-width: 960px) {
  .form-card {
    max-width: 50%;
  }
}

@media (max-width: 960px) and (min-width: 600px) {
  .form-card {
    max-width: 80%;
  }
}

@media (max-width: 600px) {
  .form-card {
    max-width: 80%;
  }

  .form-card {
    .right-col {
      border-radius: 0 !important;
      padding: 1rem 0 !important;
    }

    .left-col {
      border-radius: 0 !important;
      padding: 1rem 0 !important;
      .vue-map-container {
        height: 400px !important;
      }
    }
  }
}
</style>
