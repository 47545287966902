<template>
  <div class="footer">
    <v-container>
      <v-row>
        <v-col cols="6" sm="3">
          <h3 class="white--text font-bold mb-3">عن الموقع</h3>
          <ul class="links-list">
            <li>
              <a href="/terms" class="footer-link">الشروط والسياسات</a>
            </li>
            <li>
              <a href="/privacyPolicy" class="footer-link">سياسة الخصوصية</a>
            </li>
            <li>
              <a href="/contact" class="footer-link"> تواصل معنا</a>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="3" class="social">
          <h3
            class="social-title white--text font-bold mb-3"
            v-if="
              facebook != '' ||
              instagram != '' ||
              snapchat != '' ||
              twitter != ''
            "
          >
            تابعنا على منصاcت التواصل الاجتماعى
          </h3>

          <ul class="unstyled-list">
            <li v-if="facebook">
              <a :href="facebook" target="_blank">
                <i class="fab fa-facebook-f"></i>
              </a>
            </li>
            <li v-if="instagram">
              <a :href="instagram" target="_blank">
                <i class="fab fa-instagram"></i>
              </a>
            </li>
            <li v-if="snapchat">
              <a :href="snapchat" target="_blank">
                <i class="fab fa-snapchat-ghost"></i>
              </a>
            </li>
            <li v-if="twitter">
              <a :href="twitter" target="_blank">
                <i class="fab fa-twitter"></i>
              </a>
            </li>
          </ul>
        </v-col>
        <v-col cols="12" sm="3"></v-col>
        <v-col cols="6" sm="3">
          <div
            class="footer-appIcons"
            v-if="app_store_link || google_play_link || app_gallery_link"
          >
            <a
              :href="app_store_link"
              target="_blank"
              class="mx-2"
              v-if="app_store_link"
            >
              <img
                src="../assets/A.png"
                alt=""
                class="d-inline-block app-img"
              />
            </a>
            <a
              :href="google_play_link"
              target="_blank"
              class="mx-2"
              v-if="google_play_link"
            >
              <img
                src="../assets/G.png"
                alt=""
                class="d-inline-block app-img"
              />
            </a>
            <a
              :href="app_gallery_link"
              target="_blank"
              class="mx-2"
              v-if="app_gallery_link"
            >
              <img
                src="../assets/H.png"
                alt=""
                class="d-inline-block app-img"
              />
            </a>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "footerComp",
  data() {
    return {
      facebook:
        localStorage.getItem("facebook") != "null"
          ? localStorage.getItem("facebook")
          : "",
      instagram:
        localStorage.getItem("instagram") != "null"
          ? localStorage.getItem("instagram")
          : "",
      snapchat:
        localStorage.getItem("snapchat") != "null"
          ? localStorage.getItem("snapchat")
          : "",
      twitter:
        localStorage.getItem("twitter") != "null"
          ? localStorage.getItem("twitter")
          : "",

      google_play_link:
        localStorage.google_play_link != "null"
          ? localStorage.google_play_link
          : "",
      app_gallery_link:
        localStorage.app_gallery_link != "null"
          ? localStorage.app_gallery_link
          : "",
      app_store_link:
        localStorage.app_store_link != "null"
          ? localStorage.app_store_link
          : "",
    };
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/_variables.scss";
$footer-background: #2e3532;
.footer {
  background-color: #2e3532;
  padding: 80px 0;

  .social-title {
    font-weight: bold;
    font-size: 1.1rem !important;
  }
  .links-list {
    list-style: none !important;
    color: #ffffff;
    li {
      margin-bottom: 12px;
      a {
        text-decoration: none;
        color: #ffffff;
      }
    }
  }
  .unstyled-list {
    list-style: none !important;
    color: #ffffff;

    li {
      display: inline-block;
      margin: 0 5px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: #ffffff;
      color: $footer-background;
      text-align: center;
    }
  }

  .footer-appIcons {
    img {
      width: 120px;
      margin-bottom: 1rem !important;
    }
    text-align: center;
  }
}

@media (max-width: 600px) {
  .social {
    text-align: center !important;
  }
}
</style>
